export interface BrochureProps {
  name: string;
  intro?: string;
  address?: string | null;
  imgfile?: string | null;
  website?: string | null;
  email?: string | null;
  phone?: string | number | null;
  nelsonAirport?: number | null;
  nelson?: number | null;
  marlborough?: number | null;
  christchurch?: number | null;
  chchTransport?: number | null;
  westcoast?: number | null;
  rack1?: number | null | undefined;
  rack2?: number | null | undefined;
  facing?: 'front' | 'back' | undefined;
  qty?: number;
}

const BROCHURES: BrochureProps[] = [
  // {
  // name: "00000",
  // intro: "",
  // imgfile: "",
  // phone: null,
  // email: null,
  // address: null,
  // website: null,
  // nelsonAirport: null,
  // nelson: null,
  // marlborough: null,
  // christchurch: null,
  // chchTransport: null,
  // westcoast: null,
  // rack1: null,
  // },

  {
    name: 'Mapua Ferry',
    intro:
      'Built to accommodate cyclists and foot passengers, the ferry is only a ten minute trip from Rabbit Island to Mapua wharf and back again.  There are also plenty of cruising options available and the ferry can be hired for private functions.',
    imgfile: 'mapua-ferry__2025',
    phone: '0800 292 538',
    email: null,
    address: 'The Coolstores, 8 Aranui Rd Mapua Wharf, Nelson 7005',
    website: 'http://www.mapuaferry.co.nz',
    nelsonAirport: 40,
    nelson: 49,
  },

  {
    name: 'Wall to Wall Art',
    intro: '',
    imgfile: 'wall_to_wall__2025',
    phone: '03 548 2303',
    email: null,
    address: null,
    website: null,
    nelsonAirport: null,
    nelson: null,
    marlborough: null,
    christchurch: null,
    chchTransport: null,
    westcoast: null,
    rack1: null,
  },
  {
    name: 'Greenmeadows Café',
    intro: '',
    imgfile: 'greenmeadows-cafe__2025',
    phone: null,
    email: 'gmcafestoke@gmail.com',
    address: '491 Main Road Stoke, Nelson 7011',
    website: null,
    nelsonAirport: 49,
    nelson: null,
  },

  {
    name: 'Sheep Farm - Real NZ Tours',
    intro: '',
    imgfile: 'real-nz-tours__2024',
    nelsonAirport: 120,
    nelson: 170,
    marlborough: 122,
  },
  {
    name: 'Gala',
    intro: '',
    imgfile: 'gala__2024',
    website: null,
    christchurch: 172,
  },
  {
    name: 'Real New Zealand Tours',
    // intro: "",
    imgfile: 'real-nz-tours__2024',
    christchurch: 171,
    chchTransport: 171,
  },
  {
    name: 'Ngaio Marsh House',
    // intro: "",
    imgfile: 'ngaio-marsh-house__2024',
    christchurch: 170,
    chchTransport: 170,
  },
  {
    name: 'Silverstream Alpaca Stud',
    intro:
      'The HIIT Squad provides epic kettlebell, bodyweight and machine-based high-intensity workouts in Christchurch. From ski ergs, to deadballs, bikes to running ...',
    imgfile: 'alpaca-farmstay__2024',
    phone: '033273020',
    email: 'silverstream.nz@gmail.com',
    address: '68 Moodys Road RD. 2 Kaiapoi 7692 North Canterbury New Zealand',
    website: 'https://www.alpaca-farmstay.co.nz',
    christchurch: 122,
    chchTransport: 122,
  },
  {
    name: 'The HIIT Squad',
    intro:
      'The HIIT Squad provides epic kettlebell, bodyweight and machine-based high-intensity workouts in Christchurch. From ski ergs, to deadballs, bikes to running ...',
    imgfile: 'the-hiit-squad__2024',
    phone: '02040258981',
    email: 'frontdesk@thehiitsquad.org',
    address: '23 Stanmore Road, Christchurch, 8011',
    website: 'https://www.thehiitsquad.org',
    christchurch: 121,
    chchTransport: 121,
  },

  {
    name: 'House of Nails',
    intro: '',
    imgfile: 'house-of-nails-2024',
    phone: null,
    email: null,
    address: null,
    website: null,
    christchurch: 120,
    chchTransport: 120,
  },

  {
    name: 'Franz Josef Cinema',
    intro: '',
    imgfile: 'franz-josef-cinema__2024',
    phone: '03 752 0793',
    email: 'franzjosefcinema@heliservices.nz',
    address: '29 Main Road, Franz Josef Glacier, New Zealand',
    westcoast: 708,
  },
  {
    name: 'Milford Sound Scenic Flights',
    intro:
      'Fly with Milford Sound Scenic Flights and enjoy uninterrupted views of the magnificent Southern Alps, Fiordland National Park and Mount Aspiring National...',
    imgfile: 'milford-sound-scenic-flights--2024',
    phone: '03 442 3065',
    email: 'info@milfordflights.co.nz',
    address:
      '39 Lucas Place, Frankton, Queenstown, New Zealand Proudly NZ Owned & Operated',
    website: 'https://www.milfordflights.co.nz/',
    westcoast: 209,
  },
  {
    name: 'Jet Boat (KJet)',
    intro:
      'Take on the mighty Shotover River with Kawarau Jet (KJet) – Queenstown’s original Jet boat ride.',
    imgfile: 'kjet--2024',
    phone: '0800 52 9272',
    email: 'BOOKINGS@KJET.CO.NZ',
    address: 'Main town pier, Marine Parade, Queenstown, New Zealand',
    website: 'https://kjet.co.nz',
    westcoast: 306,
  },

  {
    name: 'Glacier Helicopters (Scenic Flights)',
    intro: 'Get inspired by the power and scale of our majestic glaciers.',
    imgfile: 'glacier-helicopters--2024',
    phone: '0800 800 732',
    email: 'fox@glacierhelicopters.co.nz',
    address: 'Main Road, Fox Glacier, New Zealand',
    website: 'https://www.glacierhelicopters.co.nz',
    westcoast: 602,
  },

  {
    name: 'Elements by IHF',
    intro:
      'Elements by IHF, a contemporary environment consisting of fresh culinary delights and refreshing beverages. From dining at the chefs table to the larger format booths, there is a variety of options to relax into your next brunch experience.',
    imgfile: 'ihf-elements--2024',
    phone: '03 365 1233',
    email: 'info@elementsbyihf.co.nz',
    address: '166 Cashel Street, Christchurch Central City',
    website: 'http://www.elementsbyihf.co.nz/',
    christchurch: 29,
    chchTransport: 29,
  },

  {
    name: 'IHF Health Club',
    imgfile: 'ihf-health-club--2024',
    phone: '0508 443 258',
    address: 'Level 1, The Crossing 166 Cashel Street Christchurch 8011',
    website: 'https://ihfhealthclub.co.nz/',
    intro:
      'Luxurious, refreshing and contemporary, we have redesigned the health and fitness industry with the best of everything - weights, coaching, cardio, classes, plunge pools, saunas and a members lounge, all located at The Crossing. With a floor space of over 1200 square meters and a no expenses spared fit out, We have made it easier and more enjoyable for you to achieve your goals. IHF Memberships are now available.',
    christchurch: 30,
    chchTransport: 30,
  },
  {
    name: 'Nelson Tasman Top 10 Tour Maps',
    intro:
      'Quality Camping Experience for Every Traveller: Whether you prefer the simplicity of camping under the stars, the convenience of a self-contained unit',
    imgfile: 'nelson-tasman-top10--2024',
    phone: null,
    email: null,
    address: null,
    website: 'https://top10.co.nz',
    nelson: 44,
    marlborough: 60,
  },
  {
    name: 'Classic Fighters Omaka 2025',
    imgfile: 'classic-fighters-omaka--2025',
    website: 'https://www.classicfighters.co.nz/',
    intro:
      "Yealands Classic Fighters Omaka is an action-packed family-friendly event and in 2023 it will be held over Easter Weekend, 7th - 9th of April. We'll be celebrating 20+ years of spectacular flying, magical moments of shows past and magnificent machines both in the air and on the ground!",
    phone: '+64 (3) 579 1305',
    nelsonAirport: 78,
    nelson: 124,
    marlborough: 27,
    christchurch: 68,
    chchTransport: 68,
    westcoast: 304,
  },
  {
    name: 'Pro Karts Nelson',
    imgfile: 'nelson__pro-karts--2024',
    website: 'http://www.prokarts.co.nz',
    nelson: 64,
    nelsonAirport: 56,
    marlborough: 91,
  },

  {
    name: 'Puzzling World',
    intro:
      "For over fifty years Puzzling World has been messing with people's heads no matter what age, nationality or experience. ",
    imgfile: 'puzzling-world--2024',
    phone: '034437489',
    email: 'info@puzzlingworld.co.nz',
    address: '188 Wanaka-Luggate Highway, Wānaka 9382',
    website: 'https://www.puzzlingworld.co.nz',
    chchTransport: 126,
    westcoast: 305,
  },
  {
    name: 'Discover Christchurch & Canterbury (official visitor guide)',
    intro:
      'Christchurch is the gateway to the Canterbury region and home to incredible experiences you will have within the South Island of New Zealand.',
    imgfile: 'christchurch_canterbury_map--2024',
    email: 'enquiries@christchurchnz.com',
    website: 'https://www.christchurchnz.com',
    nelson: 169,
    marlborough: 160,
    westcoast: 106,
  },
  {
    name: 'Discover Christchurch & Canterbury (OVG)',
    intro:
      'Christchurch is the gateway to the Canterbury region and home to incredible experiences you will have within the South Island of New Zealand.',
    imgfile: 'christchurch_canterbury_map--2024',
    email: 'enquiries@christchurchnz.com',
    website: 'https://www.christchurchnz.com',
    westcoast: 206,
  },
  {
    name: 'Pounamu Pathway',
    intro:
      'The Pounamu Pathway is an immersive storytelling experience delivered in four unique and linked hubs across the West Coast/Te Tai o Poutini, from Haast/Awarua to Westport/Kawatiri.',
    imgfile: 'pounamu-pathway--2024',
    phone: '0800%20224%20414',
    email: 'info@pounamupathway.kiwi',
    address: null,
    website: 'https://www.pounamupathway.com',
    westcoast: 502,
  },
  {
    name: 'Motueka Top 10 Holiday Park',
    intro:
      'Welcome to Motueka Top 10 Holiday Park. Your gateway to the stunning Abel Tasman National Park.',
    imgfile: 'motueka-top-10-holiday-park__2024',
    phone: '0800 668 835',
    email: 'info@motuekatop10.co.nz',
    address: '10 Fearon Street Motueka, Tasman 7120 New Zealand',
    website: 'https://www.motuekatop10.co.nz',
    nelsonAirport: 30,
  },
  {
    name: 'Black and White Coffee',
    intro:
      "Black & White Coffee is all about a unique coffee experience. We exist to pay tribute to the incredible history of the 'coffee shop' and live for the art and the culture of coffee.",
    imgfile: 'blackandwhitecoffee_2024',
    phone: null,
    email: null,
    address: null,
    website: 'https://www.blackandwhitecoffee.co.nz',
    christchurch: 119,
    chchTransport: 119,
  },
  {
    name: 'Kaikoura Zipline Adventure',
    intro:
      "Complete your ziplining journey by spreading your arms wide and gliding like the Kārearea, New Zealand's native falcon, across this spectacular 384m zipline.",
    imgfile: 'kaikoura-zipline_2025',
    phone: '03 662 0999',
    email: 'null',
    address: ' 11 West End, Kaikōura 7300',
    website: 'https://www.ecozipadventures.co.nz',
    nelson: 128,
    marlborough: 12,
    chchTransport: 39,
    christchurch: 39,
  },
  // {
  //   name: 'Creative Pathways',
  //   intro: "a map guide to our region's rural artists and crafts people",
  //   imgfile: 'creative-pathways--2024',
  //   phone: '03 543 9480',
  //   email: null,
  //   address: null,
  //   website: null,
  //   nelson: 56,
  //   nelsonAirport: 67,
  // },
  {
    name: 'Canyoning Aotearoa',
    intro: 'where nature is your adventure',
    imgfile: 'canyoning-aotearoa--2024',
    phone: '+64 27 259 2117',
    email: null,
    address: null,
    website: 'https://www.canyoningaotearoa.com',
    nelson: 54,
  },
  {
    name: 'Two Thumb Brewing',
    intro: 'Sustainably brewed award winning craft beer',
    imgfile: 'two-thumb-brewing--2024',
    phone: '03 777 0130',
    email: null,
    address: null,
    website: 'https://www.twothumb.com',
    nelson: 60,
  },
  // {
  //   name: 'Warbirds Over Wanaka',
  //   intro:
  //     'For three days over Easter, Wanaka becomes the centre-stage of a world-famous Warbirds Airshow. Some of the most famous Warbirds join forces with aircraft of yesteryear',
  //   imgfile: 'warbirds_over-wanaka-2023',
  //   phone: '+64 3 443 8619',
  //   email: 'null',
  //   address:
  //     '12 Lloyd Dunn Avenue Wanaka Airport Luggate-Wanaka State Highway 6 Wanaka 9382',
  //   website: 'https://www.warbirdsoverwanaka.com',
  //   christchurch: 110,
  //   chchTransport: 110,
  // },

  {
    name: 'Heli Rural',
    intro:
      'Heli Rural Ltd Trading as Heli Rural Methven, Mount Hutt, Rakaia and Canterbury is dedicated to providing the highest quality customer service and performance. Our customers will receive our service delivered with friendliness, reliability and honor.',
    imgfile: 'heli-rural_2023',
    phone: '027 636 6360',
    address: '535 Whitcombe Valley Road, Kokatahi, Hokitika',
    email: 'info@helirural.co.nz',
    website: 'http://www.helirural.co.nz',
    christchurch: 10,
    chchTransport: 10,
  },
  {
    name: "Glaciers Snow Landing's",
    intro:
      'West Coast NZ based, professional helicopter operation specialising in the commercial, agricultural and tourism sectors.',
    imgfile: 'glaciers-snow-landings_2023',
    phone: '0800 246 359',
    email: 'sales@precisionhelicopters.com',
    address: '562 Pudding Hill Road',
    website: 'https://precisionhelicopters.com',
    christchurch: 106,
    chchTransport: 106,
  },
  {
    name: '2 Degree',
    imgfile: 'two-degree--2024',
    website: 'https://www.2degrees.nz/',
    intro:
      'Full-service telco connecting good sorts all around New Zealand. Innovating at every turn, our people in Auckland, Wellington, and Christchurch are here to make New Zealand a better place to live.',
    christchurch: 105,
    chchTransport: 105,
    nelsonAirport: 73,
    nelson: 93,
    marlborough: 55,
  },
  {
    name: 'Moko Pounamu',
    intro:
      'A selection of carvings that are uniquely New Zealand gifts, inspired by Maori culture and New Zealand flora and fauna',
    imgfile: 'mokopounamu_2023',
    phone: '0320421206495',
    email: 'inquiries@mokopounamu.co.nz',
    address:
      '1/340 Durham Street North, Christchurch Central City, Christchurch 8013',
    website: 'https://www.mokopounamu.co.nz/',
    christchurch: 117,
    chchTransport: 117,
  },
  {
    name: 'The Cheese Shop',
    intro:
      'Discover a delightful array of over 100 cheeses, from imported to local artisanal treasures. Specializing in aged Dutch-style varieties. We offer overnight gift hamper delivery across NZ and create stunning cheese stacks for weddings and events. Elevate your cheese experience with us!',
    imgfile: 'the_cheese-shop-2023',
    phone: '03 528 4242',
    email: 'info@cheeseshop.co.nz',
    address: "Eden's Edge Lodge, 137 Lodder Lane, Riwaka",
    website: 'https://www.cheeseshop.co.nz',
    // nelsonAirport: null,
    nelson: 48,
  },
  {
    name: 'Great Journeys New Zealand',
    intro:
      "Experience a picture-perfect journey through the heart of New Zealand. From sleeping volcanoes to cascading waterfalls, sparkling cities to quaint rural farmsteads, golden beaches to snow-capped mountains. We'll bring the best of New Zealand's famous scenery to your window while you sit back and relax.",
    imgfile: 'great-journeys-nz__2024',
    phone: '0800 872 467',
    website: 'https://www.greatjourneysnz.com',
    nelson: 100,
    marlborough: 52,
    christchurch: 76,
    chchTransport: 76,
    nelsonAirport: 80,
    // westcoast: 403,
  },
  {
    name: "Emerson's Brewery Tour",
    intro: '',
    imgfile: 'emerson-brewery-tour_2023',
    phone: null,
    email: 'null',
    address: null,
    website: null,
    christchurch: 24,
    chchTransport: 24,
  },
  {
    name: 'Chch Tear off city map',
    imgfile: 'chch-citymap_2023',
    christchurch: 16,
    chchTransport: 16,
  },

  {
    name: 'Canterbury Museum',
    intro:
      "The Canterbury Museum is a museum located in the central city of Christchurch, New Zealand, in the city's Cultural Precinct. ",
    imgfile: 'canterbury-museum_2023',
    phone: '+64 3 366 5000',
    email: 'accounts@canterburymuseum.com',
    address: '66 Gloucester Street, Christchurch Central City, Christchurch',
    website: 'https://www.canterburymuseum.com/',
    christchurch: 11,
    chchTransport: 11,
  },

  {
    name: 'Untouched World',
    intro:
      'From the first and only fashion company to be recognised by the UN for sustainability. From our luxurious CoolTree™ Merino to our Sustainable ProjectU™ clothing.',
    imgfile: 'untouched_2023',
    website: 'https://www.untouchedworld.com/',
    christchurch: 89,
  },
  // {
  //   name: 'Nelson Arts Festival',
  //   intro: '11 days of unmissable arts experiences for and with Whakatū.',
  //   imgfile: 'nelson-arts-festival__2025',
  //   email: 'info@nelsonartsfestival.nz',
  //   website: 'https://www.nelsonartsfestival.nz/',
  //   nelsonAirport: null,
  //   nelson: 72,
  // },

  {
    name: 'Crater Rim Walks',
    intro:
      "Walk the Crater Rim, our top-notch guided 1-day walk on Banks Peninsula, a spectacular extinct volcano within 30 minutes of Christchurch. Experience stunning Canterbury views and enjoy a day's walk with our experienced local guides.",
    imgfile: 'crater-rim-walks_2023',
    phone: '+64 21 280 3350',
    email: 'nicole@craterrimwalks.co.nz',
    address: 'Crater Rim Walks Christchurch, New Zealand',
    website: 'https://www.craterrimwalks.co.nz',
    // christchurch: 72,
    // chchTransport: 72,
  },
  // {
  //   name: "West Coast deals - Franz Josef Glacier",
  //   intro: "Back to the Future - Franz Josef Glacier triple treat package",
  //   imgfile: "Franz-Jo-Glacier-Back-to-the-Future",
  //   phone: "03 752 0600",
  //   website: "https://www.wildkiwi.co.nz/glacier-package/",
  //   nelson: 163,
  //   marlborough: 146,
  //   christchurch: 51,
  //   chchTransport: 51,
  //   westcoast: 75,
  // },
  // {
  //   name: "West Coast deals 2 - Hokitika and Franz Josef",
  //   intro:
  //     "Back to the Future 2 - Hokitika and Franz Josef, the west coast 2 nights combo",
  //   imgfile: "Back-to-the-Future-2_2022-big",
  //   website: null,
  //   nelson: 164,
  //   marlborough: 150,
  //   christchurch: 52,
  //   chchTransport: 52,
  //   westcoast: 76,
  // },
  // {
  //   name: "West Coast deals 3 - Lake Brunner to Franz Josef",
  //   intro:
  //     "Back to the Future 3, Only $399, the west coast 3 nights triple treat",
  //   imgfile: "Back-to-the-Future-3_2022-big",
  //   phone: "03 752 0600",
  //   website: "https://www.wildkiwi.co.nz/westcoast-package/",
  //   nelsonAirport: 92,
  //   nelson: 165,
  //   marlborough: 152,
  //   christchurch: 53,
  //   chchTransport: 53,
  //   westcoast: 77,
  // },
  // {
  //   name: 'The Northern West Coast Deal',
  //   intro:
  //     'BACK TO THE FUTURE - THE NORTHERN WEST COAST - What a deal, Travel in ANY direction, and at ANY time to suit you - Only $299 per person.',
  //   imgfile: 'the-northen-wc-deal_2022',
  //   phone: '037520600',
  //   website: 'https://www.wildkiwi.co.nz/299-northen-west-coast',
  //   nelson: 166,
  //   nelsonAirport: 102,
  //   marlborough: 151,
  //   christchurch: 54,
  //   chchTransport: 54,
  //   westcoast: 75,
  //   rack1: 5,
  // },
  {
    name: 'Westcoast Wildlife Centre',
    imgfile: 'wildkiwi_2017_2',
    website: 'https://www.wildkiwi.co.nz/',
    nelsonAirport: 107,
    nelson: 156,
    marlborough: 142,
    christchurch: 52,
    chchTransport: 52,
    westcoast: 801,
    rack1: 1,
  },
  {
    name: 'White Heron Sanctuary Tours',
    imgfile: 'white_heron_sanctuary_tours',
    phone: '+643 752 0600',
    nelson: 157,
    marlborough: 140,
    chchTransport: 75,
    christchurch: 75,
    westcoast: 802,
  },
  {
    name: 'underground adventures_cafe',
    imgfile: 'underground-adventures_cafe',
  },
  {
    name: 'nelson farmers market',
    imgfile: 'nelson-farmers-market',
    nelson: 81,
  },
  {
    name: 'Explore Christchurch & Canterbury, Map',
    imgfile: 'christchurch-map--2023',
    website: 'https://www.christchurchnz.com',
    nelson: 130,
    marlborough: 101,
    christchurch: 25,
    chchTransport: 25,
  },
  {
    name: 'Golden Bay Mohua Visitor Guide',
    imgfile: 'golden-bay-ovg-2023',
    website: 'https://www.goldenbaynz.co.nz',
    nelsonAirport: 11,
    nelson: 12,
  },
  {
    name: 'Golden Bay Mohua Visitor Guide*',
    imgfile: 'golden-bay-ovg-2023',
    website: 'https://www.goldenbaynz.co.nz',
    nelsonAirport: 12,
  },
  {
    name: 'Farewell Spit Eco Tours',
    imgfile: 'farewellspittours-2017',
    website: 'https://www.farewellspit.com',
    nelsonAirport: 13,
    nelson: 11,
    marlborough: 80,
  },
  {
    name: 'Abel Tasman Region Guide',
    imgfile: 'abel-tasman-ovg__2024',
    website: 'https://www.abeltasmanisite.co.nz',
    nelsonAirport: 14,
    nelson: 15,
    marlborough: 57,
  },
  {
    name: 'Abel Tasman Region Guide*',
    imgfile: 'abel-tasman-ovg__2024',
    website: 'https://www.abeltasman.co.nz',
    nelsonAirport: 15,
  },
  {
    name: 'Abel Tasman Kayaks',
    imgfile: 'abel-tasman-kayaks__2025',
    phone: '0800732529',
    website: 'http://www.abeltasmankayaks.co.nz',
    nelsonAirport: 16,
    nelson: 18,
    marlborough: 64,
    christchurch: 69,
    chchTransport: 69,
    westcoast: 101,
  },
  {
    name: 'Abel Tasman Kayaks*',
    imgfile: 'abel-tasman-kayaks__2025',
    phone: '0800732529',
    website: 'http://www.abeltasmankayaks.co.nz',
    nelsonAirport: 17,
    nelson: 25,
    marlborough: 65,
  },
  {
    name: 'Waka Abel Tasman',
    imgfile: 'Waka-Abel-Tasman_2019-2S',
    phone: '0800 925 228',
    website: 'https://wakaabeltasman.nz',
    nelsonAirport: 18,
    nelson: 32,
  },
  {
    name: 'Kaiteriteri Recreational Reserve',
    imgfile: 'kaiteriterikayaks_2022-big',
    website: 'http://www.seakayak.co.nz',
    nelsonAirport: 21,
    nelson: 34,
    marlborough: 59,
  },
  {
    name: 'Kaiteriteri Recreational Reserve*',
    imgfile: 'kaiteriterikayaks_2022-big',
    website: 'http://www.seakayak.co.nz',
    nelsonAirport: 22,
  },
  {
    name: 'Abel Tasman Marahau Water Taxis',
    imgfile: 'marahauwatertaxi_2020-2s',

    website: 'http://www.abeltasmancentre.co.nz',
    nelson: 35,
    marlborough: 71,
  },
  {
    name: 'Abel Tasman Aquataxi',
    imgfile: 'at-aquataxi_2023',
    website: 'https://aquataxi.co.nz/',
    intro: 'Experience the Amazing Abel Tasman with AquaTaxi',
    phone: '+64 3 527 8083',
    address: '275 Sandy Bay-Marahau Rd Marahau - New Zealand',
    nelsonAirport: 23,
    nelson: 27,
    christchurch: 73,
    chchTransport: 73,
    marlborough: 70,
    westcoast: 201,
  },
  {
    name: 'Marahau Sea Kayaks',
    imgfile: 'marahau-sea-kayaks_2023',
    website: 'https://www.msk.co.nz',
    intro:
      'MSK - Marahau Sea Kayaks exploring the abel tasman national park by kayak since 1986',
    phone: '+64 3 527 8176',
    address: '229 Sandy Bay-Marahau Rd. Marahau, New Zealand',
    nelsonAirport: 24,
    nelson: 26,
    marlborough: 66,
  },
  {
    name: 'R&R Kayaks ',
    imgfile: 'rr-kayaks__2022',
    intro: 'We are a small company with a big heart',
    phone: '0508 223 224',
    website: 'http://www.rrkayaks.co.nz',
    nelsonAirport: 25,
    nelson: 29,
    marlborough: 74,
    westcoast: 302,
  },
  {
    name: 'Abel Tasman Sea Shuttle',
    imgfile: 'seashuttle__2025',
    website: 'http://www.abeltasmanseashuttles.co.nz',
    nelsonAirport: 26,
    nelson: 17,
    marlborough: 58,
    christchurch: 71,
    chchTransport: 71,
    westcoast: 301,
  },
  {
    name: 'Abel Tasman Sea Shuttle*',
    imgfile: 'seashuttle__2025',
    website: 'http://www.abeltasmanseashuttles.co.nz',
    nelsonAirport: 27,
  },
  {
    name: 'Abel Tasman Sailing Adventures',
    imgfile: 'abel-tasman-sailing-adventures_2024',
    website: 'https://www.sailingadventures.co.nz',
    nelsonAirport: 28,
    nelson: 30,
    marlborough: 72,
  },
  {
    name: 'Canyoning Abel Tasman',
    imgfile: 'canyoning-abel-tasman-national-park',
    phone: '0800 86 34 72',
    website: 'https://www.abeltasmancanyons.co.nz/',
    nelsonAirport: 31,
  },
  {
    name: 'Wilsons Abel Tasman National Park visitor guide',
    imgfile: 'wilsons-abel-tasman-national-park__2024',
    website: 'http://www.abeltasman.co.nz',
    nelsonAirport: 32,
    nelson: 36,
    marlborough: 61,
    christchurch: 70,
    chchTransport: 70,
    westcoast: 202,
  },
  {
    name: 'Wilsons Abel Tasman National Park visitor guide*',
    imgfile: 'wilsons-abel-tasman-national-park__2024',
    nelsonAirport: 33,
    nelson: 62,
    marlborough: 75,
  },
  {
    name: 'Wilsons Abel Tasman National Park visitor guide**',
    imgfile: 'wilsons-abel-tasman-national-park__2024',
    nelsonAirport: 34,
  },
  {
    name: 'Wilson Abel Tasman Sea Kayaking',
    imgfile: 'WilsonsKayaking_2019-2S',
    website: 'http://www.abeltasman.co.nz',
    nelsonAirport: 35,
    nelson: 40,
    marlborough: 62,
  },
  {
    name: 'Motueka Sunday Market',
    imgfile: 'sunday-motueka-market__2024',
    website: 'http://www.motuekasundaymarket.co.nz',
    nelsonAirport: 36,
    nelson: 43,
  },
  {
    name: "Tasman's Great Taste Trail",
    imgfile: 'tasmans-great-taste-trail__2025',
    phone: '021 802 535',
    website: 'https://www.heartofbiking.org.nz',
    intro:
      "Experience Nelson Tasman's diverse tastes with extraordinary towns, orchards, farms and vineyards, sparkling coastlines, rivers and hill valleys with either a small 'bite' or  else cycle the 200km loop. Enjoy spectacular views, sample local food, wine or brews, spot wading and sea birds, and meet friendly locals.",
    email: 'admin@heartofbiking.org.nz',
    nelsonAirport: 39,
    nelson: 47,
  },
  {
    name: "Tasman's Great Taste Trail*",
    imgfile: 'tasmans-great-taste-trail__2025',
    phone: '021 802 535',
    website: 'https://www.heartofbiking.org.nz',
    nelsonAirport: 38,
  },
  {
    name: 'Mapua',
    imgfile: 'mapua-map__2024',
    website: 'https://www.mapua.co.nz',
    nelsonAirport: 41,
    nelson: 50,
  },
  {
    name: 'Mapua*',
    imgfile: 'mapua-map__2024',
    website: 'https://www.mapua.co.nz',
    nelsonAirport: 42,
  },
  {
    name: 'Kiwi Journeys Bike cycle Hire & Tours',
    imgfile: 'kiwi-journeys__2025',
    website: 'https://www.kiwijourneys.co.nz',
    phone: '0800 292 538',
    nelsonAirport: 43,
    nelson: 45,
    westcoast: 809,
  },
  {
    name: 'Nelson Wine Guide',
    imgfile: 'nelson-wine-guide_2024',
    website: 'http://www.winenelson.co.nz',
    nelsonAirport: 44,
    nelson: 61,
    marlborough: 94,
  },
  {
    name: 'Nelson Wine Guide*',
    imgfile: 'nelson-wine-guide_2024',
    website: 'http://www.winenelson.co.nz',
    facing: 'back',
    nelsonAirport: 45,
  },
  {
    name: 'Booster Wine Group Gravity Vineyard',
    imgfile: 'gravity-winery_2022',
    phone: '03 543 2817',
    website: 'https://www.gravitywinery.co.nz/',
    nelsonAirport: 46,
  },
  {
    name: 'Booster Wine Group Gravity Vineyard*',
    imgfile: 'gravity-winery_2022',
    phone: '03 543 2817',
    website: 'https://www.gravitywinery.co.nz/',
    nelsonAirport: 47,
  },
  // {
  //   name: 'Bay Tours Nelson',
  //   imgfile: 'bay-tours_2022',
  //   website: 'http://www.baytoursnelson.co.nz',
  //   nelsonAirport: 48,
  //   nelson: 62,
  //   marlborough: 97,
  // },
  // {
  //   name: "Nelson Craft Brewing",
  //   imgfile: "Nelson-Craft-Brewing2021-Big",
  //   website: "http://www.craftbrewingcapital.co.nz",
  //   nelsonAirport: 51,
  //   nelson: 53,
  //   marlborough: 96,
  // },
  {
    name: 'Eyebright country store',
    imgfile: 'eyebright_2017_2',
    website: 'http://www.eyebright.co.nz',
    nelsonAirport: 52,
    // nelson: 76,
    // marlborough: 84,
  },
  {
    name: 'Nelson City Guide',
    imgfile: 'nelson-city-guide__2024',
    website: 'http://www.uniquelynelson.co.nz',
    nelsonAirport: 53,
    nelson: 55,
    marlborough: 83,
  },
  {
    name: 'Nelson City Guide*',
    imgfile: 'nelson-city-guide__2024',
    website: 'http://www.uniquelynelson.co.nz',
    nelsonAirport: 54,
  },
  {
    name: 'Tahuna Beach Holiday Park',
    imgfile: 'tahunaholidaypark_2022',
    phone: '0800 500 501',
    website: 'https://tahuna.nz/',
    nelsonAirport: 55,
  },
  {
    name: 'Nelson Cycle Hire',
    imgfile: 'nelson-cycle-hire_2022',
    phone: '03 539 4193',
    website: 'https://nelsoncyclehire.co.nz/',
    nelsonAirport: 59,
  },
  // {
  //   name: 'Pegasus Rental Cars',
  //   imgfile: 'pegasus-rentals_2022',
  //   phone: '0800 80 35 80',
  //   website: 'https://www.rentalcars.co.nz/',
  //   nelsonAirport: 61,
  // },
  // {
  //   name: 'Helicopters Nelson',
  //   imgfile: 'helicopters-nelson2021-Big',
  //   website: 'http://www.helicoptersnelson.co.nz',
  //   nelson: 92,
  // },
  {
    name: 'Brook Waimarama Sanctuary',
    imgfile: 'brook-waimarama-sanctuary__2024',
    phone: '035394920',
    website: 'https://www.brooksanctuary.org.nz',
    nelsonAirport: 57,
  },
  // {
  //   name: 'Brook Sanctuary A4 Pad',
  //   imgfile: 'brook-sanctuary-a4-pad__2022',
  //   phone: '035394920',
  //   website: 'https://www.brooksanctuary.org.nz',
  //   nelson: 169,
  // },
  {
    name: 'Nelson Classic Car Museum',
    imgfile: 'Nelson-Classic-Car-Museum_2020-2S',
    phone: '03 547 4570',
    website: 'https://nelsonclassiccarmuseum.nz',
    nelsonAirport: 63,
    nelson: 57,
  },
  {
    name: 'The Suter Art Gallery',
    imgfile: 'suter-art-gallery__2025',
    website: 'https://www.thesuter.org.nz',
    nelsonAirport: 64,
    nelson: 88,
    marlborough: 97,
  },
  {
    name: 'Jens Hansen - The Ringmaker',
    imgfile: 'Jens-Hansen_2017_2',
    website: 'https://www.jenshansen.com/',
    nelsonAirport: 65,
    nelson: 75,
  },
  // {
  //   name: 'Art + Crafts Nelson',
  //   imgfile: 'arts-crafts__2023',
  //   nelson: 84,
  //   nelsonAirport: 66,
  // },
  // {
  //   name: 'Code Breakers',
  //   imgfile: 'code-breaker_2023',
  //   phone: '+64 3 548 9588',
  //   website: 'https://www.nelsonmuseum.co.nz/',
  //   nelsonAirport: 68,
  // },

  {
    name: 'Nelson Museum',
    imgfile: 'nelson-museum__2025',
    phone: '+64 3 548 9588',
    website: 'https://www.nelsonmuseum.co.nz/',
    intro:
      'Creating unforgettable experiences that stimulate awareness, celebrate diversity, entertain, and excite action. Welcome to the Nelson Provincial Museum',
    nelsonAirport: 48,
    marlborough: 82,
    nelson: 86,
  },
  // {
  //   name: 'TE ARA O HINE REHEHIA',
  //   imgfile: 'te-ara-kapa-haka_2023',
  //   phone: '+64 3 548 9588',
  //   website: 'https://www.nelsonmuseum.co.nz/programme-list/tearaohinerehia',
  //   address:
  //     'The Nelson Provincial Museum, Pupuri Taonga o Te Tai Ao, Nelson 7040',
  //   nelson: 86,
  //   nelsonAirport: 48,
  // },
  {
    name: 'Cable Bay Adventure Park',
    imgfile: 'Cable-Bay-Adventure-Park-2018-2S',
    website: 'https://www.cablebayadventurepark.com',
    nelsonAirport: 69,
    nelson: 67,
    marlborough: 86,
  },
  {
    name: 'Nelson Waahi Taakaro Golf club',
    imgfile: 'nelson-golf-waahi-taakaro-2022',

    website: 'https://www.waahitaakarogolfclub.co.nz/',
    nelsonAirport: 71,
    nelson: 58,
  },
  {
    name: 'Nelson Waahi Taakaro Golf club*',
    imgfile: 'nelson-golf-waahi-taakaro-2022',

    website: 'https://www.waahitaakarogolfclub.co.nz/',
    nelsonAirport: 72,
  },
  {
    name: 'Marlborough Official Visitor Guide',
    imgfile: 'marlborough-ovg__2024',
    website: 'http://www.marlboroughnz.com',
    nelsonAirport: 76,
    nelson: 96,
    // marlborough: 16,
  },
  {
    name: 'Lochmara - Marlborough Sounds',
    intro: 'day walks & cruises, accommodation',
    imgfile: 'lochmara_2023',
    website: 'https://www.lochmara.co.nz',
    nelsonAirport: 75,
    nelson: 97,
    marlborough: 31,
  },
  {
    name: 'Holey Moley',
    imgfile: 'holey-holey__2023',
    website: 'https://www.holeymoley.co.nz',
    intro:
      "a mini golf course and bar that turns traditional putt putt into a multi-sensory labyrinth of unique holes that'll keep you on your toes across the craziest round of golf you've ever played.",
    phone: '0800727833',
    address: '617 Colombo Street, Christchurch Central City',
    christchurch: 15,
    chchTransport: 15,
  },

  {
    name: 'Omaka Aviation Heritage Centre',
    imgfile: 'omaka-aviation-heritage-centre_2023',
    website: 'http://www.omaka.org.nz',
    phone: '+64 3 579 1305',
    address: '79 Aerodrome Road, Blenheim, Marlborough, New Zealand 7272',
    intro:
      'The Omaka Aviation Heritage Centre has combined a rare collection of WW1 and WW2 aircraft with creative display techniques to present a remarkable experience that captures the imagination. Omaka is a world-class destination for appreciating historic aircraft and sharing the stories of the men and women behind the machines.',
    nelsonAirport: 77,
    nelson: 111,
    marlborough: 24,
    westcoast: 303,
    christchurch: 67,
    chchTransport: 67,
  },
  // {
  //   name: "Omaka Aviation Heritage Centre*",
  //   imgfile: "Omaka_2021-big",
  //   website: "http://www.omaka.org.nz",
  //   nelsonAirport: 77,
  // },
  {
    name: 'Marlborough Sounds Cougar Line',
    imgfile: 'Couger-line_2020-2S',
    phone: '0800 50 40 90',
    website: 'http://www.cougarline.co.nz',
    // nelsonAirport: 80,
    // nelson: 93,
    marlborough: 34,
    // christchurch: 65,
    // chchTransport: 65,
  },
  // {
  //     name: 'Pelorus Mail Boat',
  //     imgfile: 'Pelorus-Mail-Boat-2019-2S',
  //     website: 'http://www.themailboat.co.nz',
  //     nelsonAirport: 81,
  //     nelson: 94,
  //     marlborough: 32,
  //     christchurch: 67,
  //     chchTransport: 67,
  // },
  {
    name: 'Kiwirail scenic trains and ferries',
    imgfile: 'kiwirail-scenic-trains-and-ferries_2020',
    website: 'https://www.kiwirailscenic.co.nz',
    westcoast: 403,
  },
  {
    name: 'Interislander',
    imgfile: 'interislander__2023',
    website: 'https://www.interislander.co.nz/',
    intro:
      'Interislander has been connecting Aotearoa New Zealand and New Zealanders across Cook Strait for over 60 years. We are the original Cook Strait ferry. We sail between the North Island and South Island (Wellington and Picton) daily carrying passengers, cars, trucks and rail wagons. Along with the breathtaking views, enjoy our locally sourced food and beverages, cinemas, free kids activities and premium lounges on board all our ferries.',
    nelsonAirport: 79,
    nelson: 99,
    marlborough: 54,
    christchurch: 96,
    chchTransport: 96,
    westcoast: 404,
  },
  {
    name: 'Bluebridge Cook Strait ferries',
    imgfile: 'bluebridge-cook-strait-ferries--2024',
    website: 'https://www.bluebridge.co.nz',
    nelsonAirport: 81,
    nelson: 98,
    marlborough: 53,
    christchurch: 18,
    chchTransport: 18,
    westcoast: 405,
  },
  {
    name: 'Kaikoura Visitor Guide',
    imgfile: 'kaikoura-visitor-guide_2023',
    // nelsonAirport: 84,
    // nelson: 126,
    // marlborough: 11,
    christchurch: 40,
    chchTransport: 40,
  },
  {
    name: 'Encounter Kaikoura dolphin',
    imgfile: 'DolphinEncounter_2020-2S',
    website: 'http://www.encounterkaikoura.co.nz',
    nelsonAirport: 84,
    nelson: 126,
    marlborough: 11,
    christchurch: 38,
    chchTransport: 38,
    rack1: 4,
  },
  {
    name: 'Albatross Encounter Kaikoura',
    imgfile: 'albatross_encounter_2016_2',
    website: 'http://www.encounterkaikoura.co.nz',
    nelsonAirport: 85,
    nelson: 125,
    marlborough: 13,
    christchurch: 41,
    chchTransport: 41,
  },
  {
    name: 'Orana Wildlife Park',
    imgfile: 'orana-wildlife-park_2022',
    website: 'http://www.oranawildlifepark.co.nz',
    nelsonAirport: 96,
    christchurch: 97,
    chchTransport: 97,
  },
  {
    name: 'Kaikoura kayaks',
    imgfile: 'kaikoura-Kayak__2023',
    phone: '0800452456',
    website: 'https://www.kaikourakayaks.nz',
    nelsonAirport: 88,
    marlborough: 14,
    chchTransport: 37,
    christchurch: 37,
  },
  {
    name: 'Kaikoura kayaks*',
    imgfile: 'kaikoura-Kayak__2023',
    phone: '0800452456',
    website: 'https://www.kaikourakayaks.nz',
    nelsonAirport: 89,
  },
  {
    name: 'Ocean View restaurant & bar',
    intro:
      'The Ocean View Restaurant and Bar, located at Scenic Hotel Punakaiki, offers visitors a premium dining experience in one of New Zealand’s most beautiful locations. Open for Breakfast, Lunch and Dinner, our chef’s prepare delicious dishes using premium New Zealand ingredients.',
    imgfile: 'oceanview-restaurant--2024',
    phone: '03 731 1168',
    email: 'punakaiki@scenichotels.co.nz',
    website:
      'https://www.scenichotelgroup.co.nz/punakaiki/scenic-hotel-punakaiki/restaurant-and-bar/',
    westcoast: 407,
  },

  {
    name: 'Tekapo Springs & star gazing',
    imgfile: 'tekapo-springs__2025',
    website: 'http://www.tekaposprings.co.nz',
    phone: '0800235382',
    email: 'info@tekaposprings.co.nz',
    address: '300 Lakeside Drive, Lake Tekapo 7945, New Zealand',
    intro:
      'Welcome to Tekapo Springs! We are dedicated to lifting your spirits by providing the ultimate in relaxation and recreation amongst the natural beauty of a pristine, alpine environment.',
    christchurch: 74,
    chchTransport: 74,
    westcoast: 204,
  },
  // {
  //   name: 'Tekapo Springs & star gazing*',
  //   imgfile: 'tekapo-springs__2023--reversed',
  //   website: 'http://www.tekaposprings.co.nz',
  //   phone: '0800235382',
  //   email: 'info@tekaposprings.co.nz',
  //   address: '300 Lakeside Drive, Lake Tekapo 7945, New Zealand',
  //   christchurch: 73,
  //   chchTransport: 73,
  // },
  // {
  //   name: 'Tourism Waitaki',
  //   imgfile: 'Tourism-Waitaki-2021-Big',
  //   phone: '08002665226',
  //   website: 'https://waitakinz.com',
  //   nelsonAirport: 100,
  //   // christchurch: 77,
  //   // chchTransport: 77,
  // },
  {
    name: 'Southern Alps Air - Milford Sound Flight & Cruise',
    imgfile: 'southernalpsair--2024',
    address: '12 Lloyd Dunn Avenue, Wānaka 9382',
    phone: '03 443 4385',
    website: 'https://www.southernalpsair.co.nz',
    westcoast: 108,
    nelsonAirport: 147,
  },
  // {
  //   name: 'Wellington Visitor Guide',
  //   imgfile: 'WellingtonOVG_2022-big',
  //   phone: '04 916 1205',
  //   website: 'https://www.wellingtonnz.com',
  //   nelsonAirport: 119,
  //   nelson: 167,
  // },

  {
    name: 'Christchurch Attractions Pass',
    imgfile: 'christchurch-attractions-pass',
    website: 'https:/www.christchurchattractions.nz/',
    christchurch: 80,
    chchTransport: 80,
  },
  {
    name: 'Christchurch Punting',
    imgfile: 'christchurch-punting_2023',
    website: 'https:/www.christchurchattractions.nz/',
    christchurch: 81,
    chchTransport: 81,
  },
  {
    name: 'Christchurch Tram',
    imgfile: 'christchurch-tram_2023',
    website: 'https:/www.christchurchattractions.nz/',
    christchurch: 82,
    chchTransport: 82,
  },
  {
    name: 'Christchurch Gondola',
    intro: 'Combine Attractions & Save',
    imgfile: 'christchurch-gondola_2022',
    phone: '03 384 0310',
    website: 'https:/www.christchurchattractions.nz/',
    christchurch: 83,
    chchTransport: 83,
  },
  {
    name: 'Christchurch Annual Pass',
    intro: 'Combine Attractions & Save',
    imgfile: 'christchurch-gondola_2023',
    phone: '03 384 0310',
    website: 'https:/www.christchurchattractions.nz/',
    christchurch: 79,
    chchTransport: 79,
  },
  {
    name: 'Hanmer Springs Attractions',
    imgfile: 'hanmer-springs-attractions',
    website: 'http://www.hanmerspringsattractions.co.nz',
    christchurch: 44,
    chchTransport: 43,
  },
  {
    name: 'christchurch mini map',
    imgfile: 'christchurch-mini-map_2022-big',
    christchurch: 21,
    chchTransport: 21,
  },
  // {
  //   name: "Sealord",
  //   imgfile: "sealords_2022-big",
  //   website: "https://www.sealord.com/",
  //   nelsonAirport: 101,
  //   nelson: 168,
  //   marlborough: 154,
  // },
  {
    name: 'Blue Penguin gifts',
    imgfile: 'blue-penguin-gifts__2024',
    phone: '035779927',
    website: 'https://bluepenguingifts.co.nz/',
    marlborough: 47,
    nelson: 94,
  },
  // {
  //   name: 'Cape Farewell Horse Treks',
  //   imgfile: 'cape-farewell-horse-treks-2021-big',
  //   website: 'http://www.horsetreksnz.co.nz',
  //   nelson: 13,
  //   marlborough: 76,
  // },
  // {
  //   name: 'Adventure Flights Golden Bay',
  //   imgfile: 'Golden-Bay-Adventure-Flights2021-Big',

  //   website: 'http://www.adventureflightsgoldenbay.co.nz',
  //   nelson: 14,
  // },
  // {
  //   name: 'Golden Bay MOHUA arts directory',
  //   imgfile: 'Golden-Bay-Art-Directory2021-Big',

  //   website: 'http://www.goldenbaynz.co.nz',
  //   nelson: 15,
  //   marlborough: 81,
  // },
  {
    name: 'Golden Bay Air Flights',
    imgfile: 'golden-bay-air_2023',
    website: 'http://www.goldenbayair.co.nz',
    nelson: 13,
  },
  {
    name: 'Ngarua Caves',
    imgfile: 'NgaruaCaves-2021-Big',
    website: 'https://www.ngaruacaves.co.nz',
    phone: '03 528 8093',
    nelson: 14,
  },
  {
    name: 'Kahu Kayaks',
    imgfile: 'Kahu-Kayaks_2019-2S',
    website: 'http://www.kahukayaks.co.nz',
    nelson: 16,
    // marlborough: 63,
    // westcoast: 102,
  },
  // {
  //   name: 'Skydive Abel Tasman',
  //   imgfile: 'skydive_2019-2S',
  //   phone: '0800 422 899',
  //   website: 'https://www.skydive.co.nz',
  //   nelson: 41,
  //   marlborough: 73,
  //   westcoast: 37,
  // },
  // {
  //   name: 'Tasman Helicopters',
  //   imgfile: 'tasman_helicopters_2016_2',
  //   website: 'http://www.tasmanhelicopters.co.nz',
  //   nelson: 42,
  // },
  {
    name: 'Biking Nelson',
    imgfile: 'bike-nelson_2020-2S',
  },
  {
    name: 'Nelson Tasman Visitor Guide',
    imgfile: 'nelson-tasman-ovg__2024',
    website: 'https://www.nelsontasman.nz',
    nelsonAirport: 50,
    nelson: 52,
    marlborough: 99,
  },
  {
    name: 'Nelson Tasman Visitor Guide*',
    imgfile: 'nelson-tasman-ovg__2024',
    website: 'https://www.nelsontasman.nz',
    facing: 'back',
    nelsonAirport: 74,
  },
  // {
  //   name: 'Founders Heritage Park',
  //   imgfile: 'Founders2021-2S',
  //   website: 'http://www.founderspark.co.nz',
  //   nelson: 51,
  // },
  // {
  //   name: 'Nelson Museum (Generic)',
  //   imgfile: 'nelson-museum__2023a',
  //   website: 'https://www.nelsonmuseum.co.nz/',
  //   intro:
  //     'Nelson Provincial Museum Pupuri Taonga o Te Tai Ao is the kaitiaki (guardian) of social and natural history and Taonga from the Nelson and Tasman regions.',
  //   phone: '03 548 9588',
  //   address: '270 Trafalgar Street',
  // },
  {
    name: 'Nature land',
    imgfile: 'nature-land__2025',
    website: 'http://www.natureland.nz',
    nelson: 65,
    nelsonAirport: 60,
  },
  // {
  //   name: 'Cable Bay Shooting Adventures',
  //   imgfile: 'CableBayShooting2021-Big',
  //   phone: '03 545 1309',
  //   nelson: 66,
  // },
  // {
  //   name: 'Archery Park',
  //   imgfile: 'archery_park--2023',
  //   phone: '03 398 8043',
  //   website: 'https://archerypark.nz',
  //   intro:
  //     'Archery Park is dedicated to the sport of traditional archery. It is based at the Cable Bay Adventure Park and has a fully equipped target range and a 3D field archery course following a 3km course through stunning native bush.',
  //   nelson: 68,
  //   marlborough: 95,
  // },
  // {
  //   name: 'Morrison Square',
  //   imgfile: 'morrison_square_2107_2',
  //   website: 'http://www.morrisonsquare.co.nz',
  //   nelson: 72,
  // },
  {
    name: 'Chokdee',
    imgfile: 'chokdee_2016_2',
    website: 'http://www.chokdee.co.nz',
    // nelson: 73,
  },
  {
    name: 'Pics Peanut Butter Factory',
    imgfile: 'pics-peanut-butter_2023',
    phone: '+6435448402',
    address: '49 Saxton Road Stoke Nelson',
    website: 'https://www.picspeanutbutter.com',
    intro:
      "Pic's Peanut Butter World, based in sunny Nelson, home to New Zealand's BEST LOVED Peanut Butter.",
    nelsonAirport: 61,
    nelson: 77,
    marlborough: 85,
  },
  {
    name: 'Nelson Market',
    imgfile: 'nelson_market_2016_2',
    website: 'http://www.nelsonmarket.co.nz',
    nelson: 80,
    nelsonAirport: 37,
  },
  // {
  //   name: "Eat Drink Nelson",
  //   imgfile: "EatDrink-2019-2S",
  //   website: "http://www.eatdrinknelson.co.nz",
  //   nelson: 83,
  // },
  // {
  //   name: 'Hoglund Art Glass',
  //   imgfile: 'hoglund-art-glass_2018_2',
  //   website: 'http://www.hoglundartglass.com',
  //   nelson: 85,
  // },
  // {
  //   name: 'Broadgreen Nelson heritage houses',
  //   imgfile: 'nelson-heritage-houses-2022',
  //   website: 'http://www.broadgreenhistorichouse.co.nz',
  //   nelson: 87,
  // },
  // {
  //   name: 'Hardy Cars',
  //   imgfile: 'hardycars_2016_2',
  //   website: 'http://www.hardycars.com',
  //   nelson: 88,
  //   marlborough: 55,
  // },
  // {
  //     name: 'Marlborough Tour Company - Greenshell Mussel cruise',
  //     imgfile: 'marl-tour-co-greenshell-mussel-2019-2s',
  //     website: 'http://www.greenshellmusselcruise.co.nz',
  //     nelson: 123,
  //     marlborough: 35,
  // },
  {
    name: 'Beachcomber Cruises Picton',
    imgfile: 'Beachcomber_2019-2S',
    website: 'http://www.beachcombercrusies.co.nz',
    nelson: 95,
    marlborough: 23,
  },
  // {
  //   name: 'Sea Kayak Adventures',
  //   imgfile: 'sea-kayak-adventrues_2023',
  //   website: 'https://nzseakayaking.com/',
  //   address: '8 Anakiwa, RD 1 Picton 7281 Marlborough Sounds New Zealand',
  //   intro:
  //     'Sea Kayak Adventures - Sea Kayaking, Mountain Biking, Walking and Mini Golf in the Marlborough Sounds!',
  //   phone: ' 03 574 2765',
  //   email: 'info@nzseakayaking.com',
  //   nelson: 102,
  //   marlborough: 21,
  // },
  // {
  //   name: 'Yealands estate wines',
  //   imgfile: 'Yealands-2021-Big',
  //   website: 'http://www.yealands.co.nz',
  //   nelson: 103,
  //   marlborough: 36,
  //   christchurch: 66,
  //   chchTransport: 66,
  // },
  // {
  //   name: 'Wilderness Guides Marlborough Sounds',
  //   imgfile: 'WildernessGuides_2020-2S',
  //   website: 'http://www.wildernessguidesnz.com',
  //   nelson: 104,
  // },
  {
    name: 'Marlborough Sounds Adventure Company',
    imgfile: 'marlborough-sounds-advanture__2025',
    website: 'http://www.marlboroughsounds.co.nz',
    // nelson: 105,
    marlborough: 45,
  },
  {
    name: 'Marlborough Wine Tours - Sounds Connection',
    imgfile: 'sounds-connection-wine-tours__2024',
    website: 'https://www.soundsconnection.com',
    nelson: 106,
    marlborough: 42,
  },
  // {
  //     name: 'Marlborough tour company Seafood Odyssea',
  //     imgfile: 'Marlborough-tour-company_2020-2S',
  //     phone: '0800990800',
  //     website:
  //         'https://www.marlboroughtourcompany.co.nz/tours-cruises/seafood-odyssea-cruise',
  //     nelson: 107,
  //     marlborough: 33,
  //     christchurch: 64,
  //     chchTransport: 64,
  // },
  // {
  //   name: 'Whale Watch Kaikoura',
  //   imgfile: 'whale-watch_2020-2S',
  //   phone: '+64 3319 6767',
  //   website: 'http://www.whalewatch.co.nz/',
  //   nelson: 125,
  //   marlborough: 12,
  // },
  {
    name: 'Kaikoura Visitor Guide OVG',
    imgfile: 'kaikoura-visitors-guide__2024',
    website: 'https://www.kaikoura.co.nz',
    phone: '03 319 5641',
    nelson: 127,
    nelsonAirport: 87,
    marlborough: 15,
    // westcoast: 28,
  },
  {
    name: 'West Coast Untamed Natural wilderness, Glacier country',
    imgfile: 'Westcoast-Untamed-Wilderness-2022-big',
    website: 'http://www.westcoast.co.nz/',
    nelson: 131,
    marlborough: 123,
    christchurch: 53,
    chchTransport: 53,
    westcoast: 409,
  },
  {
    name: 'West Coast Untamed Natural wilderness, Glacier country',
    imgfile: 'Westcoast-Untamed-Wilderness-2022-big',
    website: 'http://www.westcoast.co.nz/',
    westcoast: 509,
  },
  // {
  //   name: 'Glacier Country Kayaks',
  //   imgfile: 'kayaks_2019-2S',
  //   website: 'http://www.glacierkayaks.com',
  //   nelson: 144,
  //   marlborough: 120,
  // },
  {
    name: 'Franz Josef Wilderness Tours',
    imgfile: 'franzjoserftours_2023',
    website: 'https://franzjoseftours.co.nz/',
    intro:
      "Experiencing a magical mirror lake a must-do while visiting the West Coast.  Our kayak tour adventures have been one of Franz Josef Glacier's top rated activities for many years.  Book a morning tour for perfectly serene rainforest and mirror lake reflections boasting panoramic views of many Glaciers and the West Coast of New Zealand's tallest peaks.",
    phone: '0800423262',
    email: 'info@franzjoseftours.co.nz',
    address:
      '30 Main Road, Franz Josef Glacier (beside the Landing Bar & Restaurant). Look for the big yellow kayak!',
    christchurch: 58,
    chchTransport: 58,
    westcoast: 709,
  },
  {
    name: 'Underworld Adventures',
    imgfile: 'Underworld-Adventures2021-Big',
    website: 'http://www.caverafting.com',
    // nelson: 132,
    // marlborough: 103,
    // christchurch: 55,
    // chchTransport: 55,
    // westcoast: 402,
    nelsonAirport: 106,
  },
  {
    name: 'Buller Gorge Swingbridge',
    imgfile: 'buller_gorge_swingbridge_2016_2',
    website: 'http://www.bullergorge.co.nz',
    nelson: 133,
    marlborough: 100,
    westcoast: 401,
  },
  {
    name: "Monteith's Brewing",
    imgfile: 'Monteiths2021-Big',
    website: 'http://www.monteiths.co.nz',
    // nelson: 134,
    // marlborough: 122,
    // christchurch: 61,
    // chchTransport: 47,
    westcoast: 501,
  },
  {
    name: 'Hokitika Cool Little Town - Hokitika Guide',
    imgfile: 'hokitika_2022',
    website: 'http://www.hokitika.org/',
    christchurch: 47,
    chchTransport: 47,
    westcoast: 408,
  },
  {
    name: 'Hokitika Glass Studio',
    imgfile: 'hokitika-glass--2024',
    website: 'https://hokitikaglass.co.nz/',
    intro:
      'A family owned business that creates some of the worlds finest glassware. Every piece is made in the workshop which you can watch being made while you peruse the extensive range of glass items in the studio.',
    phone: '03 755 7775',
    address: '61 Weld Street, Hokitika, New Zealand',
    christchurch: 50,
    chchTransport: 50,
    westcoast: 805,
  },
  {
    name: 'Hanmer Springs & Hurunui District',
    imgfile: 'visit-hurunui__2023',
    website: 'http://hanmersprings.co.nz/',
    nelsonAirport: 118,
    nelson: 136,
    marlborough: 115,
    christchurch: 43,
    chchTransport: 42,
    westcoast: 103,
  },
  {
    name: 'Shanty Town heritage park',
    imgfile: 'shanty-town__2023',
    website: 'https://www.shantytown.co.nz',
    phone: '0800 742 689',
    nelson: 137,
    nelsonAirport: 108,
    marlborough: 111,
    christchurch: 60,
    chchTransport: 60,
    westcoast: 804,
  },
  // {
  //   name: 'Mt Cook & Glaciers Wilderness Wings',
  //   imgfile: 'mtcook_glaciers_2016_2',
  //   website: 'http://www.wildernesswings.co.nz',
  //   nelson: 141,
  //   marlborough: 112,
  // },
  {
    name: 'Skydive NZ, Inflite scenic flights',
    imgfile: 'skydive-nz__2025',
    website: 'https://www.skydivefranz.co.nz',
    phone: '0800 458 677',
    nelson: 142,
    marlborough: 124,
    christchurch: 93,
    chchTransport: 93,
    // westcoast: 52,
  },

  {
    name: 'Inflite Experiences (Mt Cook Ski Planes & Helicopters)',
    imgfile: 'Inflite-Experiences_2020-2S',
    website: 'http://www.mtcookskiplanes.com',
    nelson: 143,
    marlborough: 126,
  },
  {
    name: 'Skydive Wanaka',
    imgfile: 'skydive-wanaka__2025',
    website: 'https://www.skydivewanaka.com/',
    nelson: 148,
    marlborough: 114,
    christchurch: 111,
    chchTransport: 111,
    westcoast: 808,
  },
  {
    name: 'Skydive Wanaka (中文)',
    imgfile: 'skydive-wanaka__2025--cn',
    website: 'https://www.skydivewanaka.com/',
    // christchurch: 112,
    chchTransport: 112,
    westcoast: 308,
  },
  {
    name: 'Hanmer Springs thermal pools & Spa',
    imgfile: 'hanmer-springs--2024',
    website: 'http://www.hanmersprings.co.nz',
    phone: '+64 03 315 0000',
    nelson: 129,
    marlborough: 113,
    christchurch: 42,
    chchTransport: 41,
    westcoast: 203,
  },
  {
    name: 'West Coast Glaciers Scenic Helicopter Flights',
    imgfile: 'West-Coast-Glaciers_2019-2S',
    website: 'http://www.helicopter.co.nz',
    // nelson: 152,
    // marlborough: 107,
    westcoast: 603,
  },
  {
    name: 'Scenic Flights Mt Cook & Glaciers (Air Safaris)',
    imgfile: 'Air-Safariscoverranz-Josef-Sceniccoverlights-2018-2s',
    website: 'http://www.airsafaris.co.nz',
    christchurch: 104,
    chchTransport: 104,
    westcoast: 706,
  },
  {
    name: 'Franz Josef Glaciers Heli Hike',
    imgfile: 'Franz-Josef-Heli-Hike_2019-2S',
    website: 'http://www.helicopter.co.nz',
    // nelson: 153,
    westcoast: 604,
  },
  {
    name: 'Fox Glacier Guiding',
    imgfile: 'fox-glacier__guiding--2022',
    website: 'https://www.foxguides.co.nz',
    phone: '0800 111 600',
    intro: 'Voted the most extraordinary experience in the world',
    nelson: 155,
    marlborough: 116,
    christchurch: 62,
    chchTransport: 62,
    westcoast: 606,
  },
  {
    name: 'Scenic Flights, the Helicopter line',
    imgfile: 'scenic_helicopters_2017_2',
    website: 'http://www.glacierhelicopters.co.nz',
    // nelson: 151,
    marlborough: 105,
    // westcoast: 53,
  },
  {
    name: 'Fox & Franz Josef Heli Services',
    imgfile: 'scenic_flights_fox_franz_2017_2',
    website: 'http://www.scenic-flights.co.nz',
    westcoast: 707,
    nelson: 154,
    marlborough: 104,
    chchTransport: 63,
    christchurch: 63,
  },
  // {
  //   name: 'New Zealand & Queenstown Deals (bookme)',
  //   imgfile: 'Book-Me_2022-big',
  //   website: 'http://www.bookme.co.nz',
  //   nelson: 162,
  //   marlborough: 121,
  //   christchurch: 111,
  //   chchTransport: 111,
  //   westcoast: 74,
  // },
  // {
  //   name: 'Mountain Bike Adventures',
  //   imgfile: 'Mountain-Bike-Adventures_2019-2S',
  //   website: 'http://www.nzbikingadventures.com',
  //   marlborough: 22,
  // },
  {
    name: 'Marlborough Trike Tours',
    imgfile: 'Marlborough-Trike-Tours_2020-2S',
    website: 'http://www.marlboroughtriketours.co.nz',
    phone: '021 0263 9532',
    marlborough: 26,
  },
  {
    name: 'Marlborough Farming Museum',
    imgfile: 'marlborough-farming-museum_2023',
    website: 'https://www.marlboroughfarmingmuseum.nz',
    marlborough: 28,
  },
  {
    name: 'Marlborough Fishing Charters',
    imgfile: 'marlborough_fishing_charters_2016_2a',
    website: 'http://www.soundsconnection.co.nz',
    marlborough: 30,
  },
  {
    name: 'Marlborough tour company - Chinese',
    imgfile: 'Marlbough-tour-company-chinese_2020-2S',
    phone: '0800 99 0800',
    website: 'http://www.marlboroughtourcompany.co.nz',
  },
  // {
  //   name: 'Moa Brewing Co Tap Room',
  //   imgfile: 'Moa-Brewing-Co_2021-big',
  //   phone: '035725146',
  //   marlborough: 44,
  // },
  {
    name: 'Highlight Wine Tours',
    imgfile: 'highlight_winetours_2016_2',
    website: 'http://www.highlightwinetours.co.nz',
    marlborough: 37,
  },
  {
    name: 'Wine and Food Tours Marlborough',
    imgfile: 'Marl-Wine-Tours-2018_2a-2',
    website: 'http://marlboroughwinetours.co.nz/',
    intro:
      "Get a Taste of New Zealand's Premier Wine Region With the Original Marlborough Wine Tours.",
    marlborough: 41,
  },
  {
    name: 'Marlborough Wine Tours',
    imgfile: 'marl-wine-tours-2024',
    website: 'https://marlboroughwinetours.co.nz/',
    intro:
      "Get a Taste of New Zealand's Premier Wine Region With the Original Marlborough Wine Tours.",
    phone: '0800 574 288',
    nelsonAirport: 82,
  },
  {
    name: 'Discovery Wine Tours',
    imgfile: 'discovery-wine-tours-nz_2023',
    address: '17 Tai Close, Kaiuma Bay, Marlborough, 7178, New Zealand',
    website: 'https://naclachanwinetours.co.nz',
    phone: '027 776 2694',
    intro:
      "Take a relaxed and friendly tour with us around some of Marlborough's finest wineries. Discover the history and stories of vines and wines in the region while you sample a range of Marlborough's gorgeous wines. Na Clachan were contract growers for Allan Scott Family Winemakers for many years so will help you unlock the mysteries",
    marlborough: 43,
  },
  {
    name: 'Get Bowling - Blenheim Indoor Sports Centre',
    imgfile: 'BlenheimIndoorSports_2020-2S',
    website: 'https://blenheimindoorsports.co.nz',
    marlborough: 46,
  },
  {
    name: 'The Vines Village',
    imgfile: 'vines_village_2016_2',
    website: 'http://www.thevinesvillage.co.nz',
    marlborough: 51,
  },
  // {
  //   name: 'Scenic Helicopter Flights',
  //   imgfile: 'THL-Scenic-Flights_2019-2S',
  //   website: 'http://www.helicopter.co.nz',
  //   marlborough: 106,
  // },
  // {
  //   name: "Mackenzie region visitor guide",
  //   imgfile: "mackenzie_2019-2S",
  //   website: "https://mackenzienz.com",
  //   marlborough: 141,
  //   chchTransport: 72,
  //   christchurch: 72,
  //   westcoast: 22,
  // },
  // {
  //   name: 'Selwyn Visitor Guide',
  //   imgfile: 'sensational-selwyn-2018-2a',
  //   website: 'http://www.sensationalselwyn.co.nz',
  //   marlborough: 145,
  // },
  {
    name: 'Selwyn Recreation Guide',
    imgfile: 'selwyn_recreation_guide-2023',
    // website: 'http://www.sensationalselwyn.co.nz',
    nelson: 143,
    marlborough: 158,
  },
  {
    name: 'Selwyn Visitor Guide (Explore Selwyn)',
    imgfile: 'selwyn-nz--2024',
    intro:
      'Watch the dramatic Southern Alps and rolling foothills rise above the fields. See the rivers flowing from the high country across the rural plains, out to Te Waihora/Lake Ellesmere and the coast. Explore the farms and small towns dotting the patchwork plains right across the region.',
    website: 'https://selwyn.nz',
    marlborough: 144,
    westcoast: 104,
  },
  // {
  //   name: 'Selwyn from the land by the season',
  //   imgfile: 'selwyn-by-the-season2021-Big',
  //   website: 'http://selwyn.nz',
  //   chchTransport: 107,
  //   christchurch: 107,
  // },
  {
    name: 'Selwyn Recreation Guide',
    imgfile: 'selwyn-recreation-guide_2023',
    website: 'http://selwyn.nz',
    chchTransport: 107, // place with new image Recreation Guide Selwyn
    christchurch: 107,
  },
  {
    name: 'Recreation Guide Selwyn',
    imgfile: 'selwyn-explore__2023',
    website: 'http://selwyn.nz',
    nelson: 145,
    nelsonAirport: 97,
    marlborough: 159,
    chchTransport: 108,
    christchurch: 108,
    westcoast: 105,
  },
  {
    name: "Coriander's ethnic Indian restaurant",
    imgfile: 'corianders2021-Big',
    website: 'https://www.corianders.co.nz/',
    phone: '03 365 5557',
    christchurch: 118,
    chchTransport: 118,
  },
  // {
  //   name: "Mobile Massage",
  //   imgfile: "Mobile-Massage2021-Big",
  //   phone: "027 247 3900",
  //   website: "https://massage-christchurch.co.nz/",
  //   christchurch: 12,
  //   chchTransport: 12,
  // },
  {
    name: 'Kate Sheppard',
    imgfile: 'Kate-Sheppard2021-Big',
    phone: '03 341 1360',
    website:
      'https://www.heritage.org.nz/places/places-to-visit/canterbury-region/kate-sheppard-house',
    christchurch: 13,
    chchTransport: 13,
    rack2: 2,
  },
  // {
  //     name: 'Valentines buffet restaurant',
  //     imgfile: 'valentines-buffet-restaurant__2022',
  //     website: 'https://valentines.co.nz',
  //     phone: '0800 825 283338',
  //     intro: 'ALL YOU CAN EAT FOR A FIXED PRICE',
  //     christchurch: 14,
  //     chchTransport: 14,
  // },
  // {
  //   name: 'little andromeda',
  //   imgfile: 'little-andromeda__2023',
  //   phone: '021 223 6693',
  //   website: 'https://littleandromeda.co.nz',
  //   christchurch: 15,
  //   chchTransport: 15,
  // },
  // {
  //   name: 'Museum of toys & collectiibles',
  //   imgfile: 'Toy-Museum2021-Big',
  //   phone: '03 366 7785',
  //   website: 'http://www.nzmuseumoftoys.com/',
  //   christchurch: 16,
  //   chchTransport: 16,
  // },
  {
    name: 'Methven Adventure Balloons',
    imgfile: 'adventure-balloons_2023',
    phone: '03 302 8172',
    email: 'info@adventureballoonsnz.com',
    intro:
      "Our Scenic Hot Air Balloon Flights take place from the foothills of Mt Hutt, Methven near Christchurch, NZ. We offer a 4hr, once in a life time experience, at very competitive prices, in one of New Zealand's most beautiful areas. With over 30years of experience, our Pilot will fly you across this magnificent landscape, pointing our areas of interest.",
    website: 'https://www.adventureballoonsnz.com',
    christchurch: 17,
    chchTransport: 17,
  },
  {
    name: 'Christchurch Casino',
    imgfile: 'casino2021-Big',
    phone: '03-365 9999',
    website: 'https://www.christchurchcasino.co.nz',
    christchurch: 22,
    chchTransport: 22,
    rack1: 3,
  },
  {
    name: 'The Arts Centre',
    imgfile: 'Arts-Centre2021-Big',
    website: 'https://www.artscentre.org.nz',
    christchurch: 23,
    chchTransport: 23,
  },
  {
    name: 'Ravenscar House Museum',
    imgfile: 'Ravenscar2021-Big',
    phone: '+64 3 366 5052',
    website: 'https://www.ravenscarhouse.com/',
    christchurch: 24,
  },
  // {
  //   name: 'Ski Field Transport Service (Snowman shuttles)',
  //   imgfile: 'Snowman-Shuttle-2021-Big',
  //   phone: '0800 766 962',
  //   website: 'http://snowmanshuttles.co.nz',
  //   christchurch: 26,
  //   chchTransport: 26,
  // },
  {
    name: 'Caddyshack city indoor mini golf',
    imgfile: 'caddyshack-city__2022',
    phone: '03 384 1566',
    website: 'http://caddyshack.co.nz',
    intro:
      'kids parties xmas functions, social groups, rest homes, school holiday programmes',
    christchurch: 27,
    // chchTransport: 27,
  },
  {
    name: 'Quake City',
    imgfile: 'Quake-city2021-Big',
    phone: '033658375',
    website: 'https://quakecity.co.nz',
    christchurch: 28,
    chchTransport: 28,
  },
  {
    name: 'Ferrymead Heritage Park',
    imgfile: 'ferrymead-heritage-park2021-Big',
    website: 'http://www.ferrymead.org.nz',
    christchurch: 31,
    chchTransport: 31,
  },
  {
    name: 'Ferrymead Golf Leisure Park',
    imgfile: 'ferrymeadgolf_2024',
    address: '68 - 100 Ferrymead Park Drive Ferrymead Park, Christchurch',
    intro:
      "Ferrymead Golf and Leisure Park is Christchurch's premier family fun centre. With so much to do in one beautifully scenic venue, you'll be coming back to visit time and time again.",
    phone: '03 376 5350',
    website: 'https://ferrymeadgolf.co.nz/',
    christchurch: 32,
    chchTransport: 32,
  },
  // {
  //   name: 'Mid Canterbury OVG',
  //   imgfile: 'Mid-canterbury-OVG-2021-Big',
  //   website: 'https://midcanterburynz.com',
  //   christchurch: 33,
  //   chchTransport: 33,
  // },
  {
    name: 'Akaroa French Connection',
    imgfile: 'Akaroa2021-Big',
    website: 'http://www.akaroabus.co.nz',
    christchurch: 34,
    chchTransport: 34,
  },
  {
    name: 'Akaroa Swimming with Dolphins',
    imgfile: 'swimming-with-dolphins2021-Big',
    website: 'http://www.swimmingwithdolphins.co.nz',
    christchurch: 35,
    chchTransport: 35,
    rack2: 4,
  },
  {
    name: 'Akaroa Harbour Nature Cruises - black cat',
    imgfile: 'BC-Nature2021-Big',
    website: 'https://blackcat.co.nz/akaroa-harbour-nature-cruises',
    christchurch: 36,
    chchTransport: 36,
  },
  {
    name: 'Hanmer Connection',
    imgfile: 'Hanmer2021-Big',
    website: 'http://www.hanmerconnection.co.nz',
    christchurch: 44,
    chchTransport: 44,
  },
  {
    name: 'The Spa at Hanmer Springs - A world away',
    imgfile: 'Hanmer-Day-Spa-Brochure-front_2022-big',
    website: 'http://hanmersprings.co.nz/',
    christchurch: 46,
    chchTransport: 45,
  },
  {
    name: 'Quad Bikes (Wilderness Tours)',
    imgfile: 'quad-bikes--2024',
    intro:
      'Explore further in Franz Josef Glacier on our top-rated guided Off-road Adventure Tour.  Choose a single quad bike, double quad bike or an adventure buggy. Free photographs are included with each tour… ',
    website: 'https://franzjoseftours.co.nz/off-road-adventure-tour/',
    address: '30 Main Road Franz Josef Glacier',
    phone: '0800423262',
    westcoast: 710,
  },
  // {
  //   name: "Central Otago Visitor Guide & Map",
  //   imgfile: "central-otago-visitor-guide-2019-2S",
  //   website: "http://www.centralotagonz.com",
  //   christchurch: 76,
  //   chchTransport: 76,
  //   westcoast: 23,
  // },
  // {
  //   name: "Zipline ziptrek ecotours",
  //   imgfile: "ziptrek_2019-2S",
  //   phone: "0800 947 873",
  //   website: "https://www.ziptrek.co.nz",
  //   christchurch: 78,
  //   chchTransport: 78,
  //   westcoast: 34,
  // },
  {
    name: 'The Tannery',
    imgfile: 'the_tannery--2022',
    website: 'https://thetannery.co.nz/',
    phone: '0800 4 TANNERY',
    address: '3 Garlands Road, Woolston, Christchurch 8023',
    intro:
      'The Tannery boutique shopping centre has a range of exciting and unique shops, housed in an amazing architectural shopping destination. The Tannery is the place for individuals to shop, people who love quality, like to make ethical purchases, and want to support local crafts and artisans. It is a hub of creativity and hospitality, retail, craft and entertainment. Covering fashion, gifts, homewares, crafts, dining, and health & beauty, there is something for everyone.',
    christchurch: 78,
    chchTransport: 78,
  },
  {
    name: 'Tramway Resaurant',
    imgfile: 'tramway-resaurant-2022',
    phone: '03 336 7830',
    website:
      'https://www.christchurchattractions.nz/christchurch-tramway-restaurant/',
    christchurch: 84,
    chchTransport: 84,
    rack2: 3,
  },
  {
    name: 'Ballooning Canterbury',
    imgfile: 'ballooning_canterbury_2016_2',
    website: 'http://ballooningcanterbury.com',
    christchurch: 91,
    chchTransport: 91,
    rack2: 1,
  },
  {
    name: 'Christchurch Adventure Park',
    intro:
      "NZ's longest and highest ziplines in a 4 zip adventure, MOUNTAIN BIKING - ZIPLINING - SIGHTSEEING - HIKING",
    imgfile: 'chch-adventure-park_2023',
    website: 'https://christchurchadventurepark.com/',
    phone: '0508247478',
    address: '225 Worsleys Road, Cracroft, Christchurch 8022',
    // nelsonAirport: 100,
    christchurch: 85,
    chchTransport: 85,
  },
  {
    name: 'Christchurch Adventure Park: ziplines',
    intro: "NZ's longest and highest ziplines in a 4 zip adventure",
    imgfile: 'christchurch-adventure-park_ziplines-2023',
    website: 'https://christchurchadventurepark.com/',
    // nelsonAirport: 101,
    christchurch: 86,
    chchTransport: 86,
  },
  {
    name: 'Mi Woollies - Sheepskin & footerwear factory shop',
    imgfile: 'miwoolies_2016_2',
    website: 'http://www.miwoollies.com',
    christchurch: 87,
    chchTransport: 87,
  },
  // {
  //   name: 'Dress Smart',
  //   imgfile: 'dress-smart__2024',
  //   website: 'https://christchurch.dress-smart.co.nz',
  //   christchurch: 88,
  //   chchTransport: 88,
  // },
  {
    name: 'Skydive NZONE',
    imgfile: 'skydive-nzone--2024',
    intro:
      "Skydive from 15000ft over Queenstown with New Zealand's FIRST tandem skydive operation. Operating for over 30 years and with 350 000 Tandem Skydives.",
    address: '35 Shotover Street, Queenstown',
    email: 'reservations@nzoneskydive.co.nz',
    website: 'https://www.nzoneskydive.co.nz/',
    westcoast: 807,
    christchurch: 123,
    chchTransport: 123,
  },
  {
    name: 'Skydive NZONE (中文)',
    imgfile: 'skydive-nzone-cn--2024',
    intro:
      "Skydive from 15000ft over Queenstown with New Zealand's FIRST tandem skydive operation. Operating for over 30 years and with 350 000 Tandem Skydives.",
    address: '35 Shotover Street, Queenstown',
    email: 'reservations@nzoneskydive.co.nz',
    website: 'https://www.nzoneskydive.co.nz/',
    westcoast: 307,
    christchurch: 124,
    chchTransport: 124,
  },

  // {
  //   name: "Six Must Do Attractions",
  //   imgfile: "sixmustdoattractions_2016_2",
  //   website: "http://www.chchattractions.co.nz/en",
  //   christchurch: 91,
  //   chchTransport: 91,
  // },
  {
    name: 'Guided Walk Christchurch',
    imgfile: 'The-Walk-CHC2021-Big',
    website: 'http://www.christchurchnz.com',
    christchurch: 92,
    chchTransport: 92,
  },
  {
    name: 'Antarctic Centre',
    imgfile: 'antartic-centre__2024',
    website: 'https://www.iceberg.co.nz/',
    phone: '+64 33570519',
    address: '38 orchard Road Christchurch 8052',
    intro:
      'located next to Christchurch international airport and open every day of the year, the international antarctic centre has a huge range of experiences guaranteed to entertain the whole family.',
    christchurch: 95,
    chchTransport: 95,
  },
  {
    name: 'antarctic centre (chinese)',
    imgfile: 'antartic-cantre-chinese',
  },
  {
    name: 'Willowbank Wildlife Experience',
    imgfile: 'willowbank-wildlife',
    phone: '03 359 6226',
    website: 'http://www.willowbank.co.nz',
    christchurch: 98,
    chchTransport: 98,
  },
  {
    name: 'Learn to Surf',
    imgfile: 'learntosurf_2017_2',
    phone: '0800 80 7873',
    website: 'http://surfcoach.co.nz/',
    christchurch: 101,
    chchTransport: 101,
  },
  {
    name: 'Airforce Museum',
    imgfile: 'airforce_museum--2022',
    website: 'http://www.airforcemuseum.co.nz/',
    christchurch: 102,
    chchTransport: 102,
  },
  {
    name: 'Riccarton House Heritage Tours',
    imgfile: 'riccarton_heritagetours_2018-2',
    phone: '03 341 1018',
    website: 'http://www.riccartonhouse.co.nz',
    christchurch: 103,
    chchTransport: 103,
  },
  {
    name: 'Jet Thrills airboat or jet boat',
    imgfile: 'Jet-Thrills2021-Big',
    website: 'http://www.jetthrills.com',
    // christchurch: 105,
    // chchTransport: 105,
  },
  {
    name: 'Treetops Walkway - Walk the treetops',
    imgfile: 'treetop-walk_2023',
    phone: '0508 873 38677',
    website: 'https://www.treetopsnz.com',
    westcoast: 803,
    christchurch: 49,
    chchTransport: 49,
    nelsonAirport: 109,
  },
  {
    name: 'Waiho hot tubs',
    imgfile: 'Waiho-hot-tubs2021-Big',
    website: 'https://waihohottubs.co.nz',
    phone: '03 752 0009',
    westcoast: 609,
  },
  // {
  //   name: "EFG Experience Fox Glacier",
  //   imgfile: "efg-experience-fox-glacier-2021-big",
  //   website: "https://experiencefoxglacier.co.nz",
  //   phone: "027 202 8965",
  //   westcoast: 14,
  // },
  {
    name: 'OXBOW adventure co.',
    imgfile: 'oxbow-adventure2021-Big',
    website: 'https://oxbow.co.nz',
    phone: '0508 4 69269',
    westcoast: 208,
  },
  {
    name: 'Siberia Experience',
    imgfile: 'siberia_2016_2',
    website: 'http://www.siberiaexperience.co.nz/',
    westcoast: 109,
  },
  // {
  //   name: 'Milford Sound Mitre Peak Cruises',
  //   imgfile: 'ms_2019-2S',
  //   website: 'http://www.mitrepeak.com',
  //   westcoast: 31,
  // },
  {
    name: 'Milford Sound Visitor Guide - Southern Discoveries',
    intro:
      "Milford Sound Cruise — Epics Fiords, Playful Dolphins, Full-Throttle Falls. It's All Here. We Get You Closer. Voted The Best Cruise Experience In Milford Sound. Daily Cruises And Add-Ons. Milford Sound Deals. Paddle With Dolphins. Milford Sound Day Trips.",
    imgfile: 'southerndiscoveries--2024',
    website: 'https://www.southerndiscoveries.co.nz/',
    christchurch: 113,
    chchTransport: 113,
    nelson: 149,
    nelsonAirport: 149,
    marlborough: 125,
    westcoast: 107,
  },
  {
    name: 'Milford Sound Select, day trip',
    imgfile: 'milfordsound_daytrip_2018_2',
    website: 'http://www.milfordsoundselect.com',
    phone: '0800 477 479',
    westcoast: 207,
  },
  // {
  //   name: 'On Yer Bike! off road adventures',
  //   imgfile: 'On-Yer-Bike_2022-big',
  //   website: 'http://onyerbike.co.nz/',
  //   westcoast: 42,
  // },
  {
    name: 'Knife, Barrytown kifemaking',
    imgfile: 'knife_2016_2',
    website: 'http://www.barrytownknifemaking.com',
    westcoast: 503,
  },
  {
    name: 'Precision Helicopters Hokitika - Glacier Tours',
    imgfile: 'precisionhelitours--2024',
    intro:
      'Our tours are designed to showcase the breathtaking beauty of New Zealand, with a focus on the stunning glaciers, snow landings, and amazing views that the Southern Alps are famous for.',
    address: '535 Whitcombe Valley Road, Kokatahi 7881',
    email: 'sales@precisionhelicopters.com',
    website: 'https://www.precisionhelitours.com/',
    phone: '0800 246 359',
    westcoast: 505,
  },
  {
    name: 'Waynes Waitoto River Safari',
    imgfile: 'waynes_waitoto_riversafarai_2016_2',
    website: 'http://www.riversafaris.co.nz',
    westcoast: 507,
  },
  {
    name: 'South Westland Salmon Farm',
    imgfile: 'South-West-Salmon2022-big',
    website: 'http://www.salmonfarm.co.nz',
    westcoast: 508,
  },
  {
    name: 'Mountain Helicopters',
    imgfile: 'mountain_helicopters_fox_franzjosef_glaciers_2017_2',
    website: 'http://www.mountainhelicopters.co.nz',
    westcoast: 605,
  },
  {
    name: 'Mt. Cook Glacier Guiding',
    imgfile: 'mtcookguides--2024',
    website: 'https://www.mtcookguides.co.nz/',
    intro:
      'Embark on an extraordinary adventure and experience the magnificent Aoraki/Mt Cook region by heli-hiking, snowshoeing or ice climbing on Haupapa/the Tasman Glacier.',
    address: 'Aoraki Mt Cook Airport,, Unit 2, State Highway 80, 7999',
    email: 'info@mtcookguides.co.nz',
    phone: '0800 682 665',
    westcoast: 506,
    christchurch: 125,
    chchTransport: 125,
  },
  {
    name: 'Mount Cook Ski Planes (Inflite)',
    imgfile: 'Mount-Cook-Ski-Planes-2019-2S',
    website: 'http://inflitemountcook.co.nz',
    phone: '0800 800 702',
    // westcoast: 608,
  },
  {
    name: 'Mount Cook Ski Planes (Inflite)',
    imgfile: 'inflit-skydive--2024',
    intro:
      'Jump the best sky in New Zealand from the most scenic South Island drop zones in Abel Tasman, Franz Josef & Fox Glacier, and Aoraki/Mount Cook at Pukaki!',
    website: 'https://www.skydive.co.nz/',
    // website: 'https://infliteexperiences.co.nz/',
    phone: '0800 835 082',
    westcoast: 608,
  },
  {
    name: 'Glacier Valley Eco Tours',
    imgfile: 'guide-nature-walks_2022',
    website: 'http://www.glaciervalley.co.nz',
    westcoast: 704,
  },
  {
    name: 'Alice May restaurant and bar',
    imgfile: 'Alice-May-2018-2s',
    website: 'https://www.instagram.com/alicemayrestaurant/',
    phone: '03 7520740',
    westcoast: 601,
  },
  {
    name: 'Glacier Shuttles & Charters Franz Josef',
    imgfile: 'glacier-shuttles--2024',
    website: 'https://www.nzbrochures.com',
    phone: '0800 999 739',
    westcoast: 703,
  },
  {
    name: 'Glacier Country Helicopters',
    imgfile: 'glacier-country-helicopters__2023',
    website: 'http://glaciercountryhelicopters.co.nz',
    phone: '03 7520 203',
    christchurch: 109,
    chchTransport: 109,
    westcoast: 705,
  },
  // {
  //   name: "Footprints on the Coast",
  //   imgfile: "Footprints-on-the-Coast-2019-2S",
  //   website: "https://tohuwhenua.nz/west-coast",
  //   westcoast: 74,
  // },
  {
    name: 'West Coast Deals ($5 off)',
    imgfile: 'West-Coast-Deals_2019-2S',
    website: 'http://www.wildkiwi.co.nz',
    westcoast: 205,
  },
  {
    name: 'Okarito Boat lake Tours',
    imgfile: 'okarito-boat-eco-tours_2022',
    website: 'http://www.okaritoboattours.co.nz/',
    westcoast: 701,
  },
  {
    name: 'Abel Tasman Magazine',
    imgfile: 'abel-tasman-magazine__2024',
    nelson: 31,
    // nelsonAirport: 103,
    marlborough: 56,
    chchTransport: 45,
    christchurch: 45,
  },
  {
    name: 'He Puna Taimoana - Hot pools by the sea',
    imgfile: 'he-puna-taimoana-2022',
    phone: '+6439417818',
    intro:
      'He Puna Taimoana and onsite Café Saturdays are operating under the COVID-19 Protection Framework (or “traffic light system”). At Red we will be fully open, including our sauna and steam room.',
    website: 'https://hepunataimoana.co.nz',
    christchurch: 12,
    chchTransport: 12,
    rack1: 2,
  },
  // {
  //   name: 'THE LEGEND OF THE LOST PIRATE GEMS',
  //   intro:
  //     "Have you heard the legend of Christchurch's lost pirate gems? 150 years ago, an evil pirate named Captain Goldeye hid three priceless gems in the foundations of Christchurch.",
  //   imgfile: 'legend-of-the-lost-pirate-gems_2022',
  //   website: 'http://nzquest.co.nz',
  //   christchurch: 14,
  //   chchTransport: 14,
  // },
  {
    name: 'Hokitika & Franz Josef, the West Coast Getaway',
    intro:
      'Including Helicopter Flight Over Franz Josef Glacier AND entry to see Live Kiwi - West Coast Wildlife Centre!',
    imgfile: 'hokitika-franz-josef-package__2022',
    phone: '+64 (3) 752 0600',
    website: 'https://www.wildkiwi.co.nz/west-coast-hokitika-franz-josef/',
    nelsonAirport: null,
    nelson: 168,
    marlborough: 154,
    christchurch: 51,
    chchTransport: 51,
    westcoast: 806,
    rack2: 5,
  },
  {
    name: 'Tohu Whenua',
    intro: 'Te Tai Poutini West Coast',
    imgfile: 'tohu-whenua__2025',
    website: 'https://tohuwhenua.nz/te-tai-poutini-west-coast',
    westcoast: 406,
  },
  {
    name: 'Pure Milford - Milford Sound Cruise',
    intro:
      'Pure Milford takes you deep into the untouched wilderness of Fiordland National Park to find your wild place. Piopiotahi / Milford Sound is home to stunning wildlife, expansive mountain ranges and thousand year old waterfalls. Sip complimentary tea and coffee or even indulge in a cold beer while you watch bottlenose dolphins dance around the bow, have a once in a lifetime shower under a 151 metre waterfall and be stunned by some of the best views in the world.',
    imgfile: 'pure-milford_2022',
    phone: '0800 500 121',
    website: 'https://puremilford.co.nz/',
    westcoast: 309,
  },
  {
    name: 'Shades of jade',
    intro: 'Quality West Coast Jade',
    imgfile: 'shades-of-jade_2022',
    phone: '+64 3 768 0794',
    website: 'https://www.shadesofjade.co.nz/',
    westcoast: 504,
  },
  {
    name: 'Wall to Wall Art Gallery',
    intro:
      '112 Bridge Street, Nelson. The gallery is light, bright, vibrant and colourful, and is brimming with an inspiring selection of original artwork, art cards, prints and gifts by established and emerging artists, including work from regular guest/featured artists which change on a monthly basis. Media include watercolour, oil, pastel, acrylic, mixed media, 3D, fused glass, textile art and photography',
    imgfile: 'wall-to-wall__2025',
    address: '112 Bridge Street, Nelson',
    phone: '+643 548 3961',
    website: 'https://www.walltowallartnelson.co.nz',
    nelson: 83,
    // nelsonAirport: null,
    // marlborough: null,
  },
  {
    name: 'Nelson Craft Beer Trail',
    intro:
      'Beer has been central to the local culture of the Nelson-Tasman region, dating back to the first New Zealand settlements in the 1840s. Early German settlers in Sarau found the Moutere to be an ideal location and climate to cultivate hops, and an extensive brewing tradition followed.',
    address: '38, Buxton Square, Nelson',
    imgfile: 'nelson-craft-beer-trail_2022',
    phone: null,
    website: 'https://www.craftbrewingcapital.co.nz',
    nelson: 53,
    nelsonAirport: 51,
    marlborough: 96,
  },
  {
    name: 'Okarito Kayaks',
    intro:
      "Okarito is a small peaceful village on the western sea coast of the South Island. Only 13km off the main highway, Okarito is about 25 minutes drive north of Franz Josef. Look for the turn off between Lakes Mapourika and Wahapo. Okarito has about 50 or so houses, but no postal service, no banks or shops. It's a bit of a haven from the rest of the world. LEARN MORE ABOUT OKARITO'S HISTORY",
    address: '1 The Srand, Okarito',
    imgfile: 'okarito-kayaks_2022',
    phone: '0800 OKARITO (652 748)',
    website: 'https://www.okarito.co.nz/',
    westcoast: 702,
  },
  // {
  //   name: 'The Free Marketeers',
  //   intro: '',
  //   imgfile: 'free-marketeers_2022-big',
  //   phone: null,
  //   address: null,
  //   website: 'https://thefreemarketeers.nz/',
  //   nelson: 82,
  //   marlborough: 98,
  // },
  // {
  //   name: 'Marlborough Scenic & Wilderness Tours',
  //   intro:
  //     'Take in rolling hills and sprawling landscapes as we head off the beaten track and into the wilderness.',
  //   imgfile: 'marlborough-scenic-wilderness-tours-2022',
  //   phone: '027 292 55 55 ',
  //   website: 'http://www.marlboroughsawtours.co.nz/',
  //   nelsonAirport: null,
  //   nelson: null,
  //   marlborough: 17,
  // },
  // {
  //   name: 'Moxini Home',
  //   intro: 'Anything but ordinary',
  //   imgfile: 'moxini-home-2023',
  //   email: 'home@moxini.co.nz',
  //   phone: '03 544 1515',
  //   address: '149C Salisbury Rd. Richmond',
  //   website: 'https://www.moxini.co.nz',
  //   nelson: 79,
  // },
  // {
  //   name: 'Nelson Buskers Festival',
  //   intro: '',
  //   imgfile: 'nelson-buskers-festival_2023',
  //   phone: null,
  //   address: 'Upper Trafalgar Street, Nelson',
  //   website: 'https://itson.co.nz',
  //   nelson: 170,
  // },
  {
    name: 'The Gentle Cycling Compnay',
    intro:
      'Premium quality cycling tours based on and around the Great Taste Trail for lovers of food, wine & beautiful Nelson landscapes',
    imgfile: 'gentle-cycling-co__2024',
    phone: '0800 932 453',
    email: 'info@gentlecycling.co.nz',
    address: '411 Nayland Road Stoke, Nelson New Zealand',
    website: 'https://www.gentlecycling.co.nz',
    nelsonAirport: 58,
    nelson: 46,
  },
  // {
  //   name: 'Waka on Avon',
  //   intro: "A completely new way to experience Chistchurch's 180 year history",
  //   imgfile: 'waka-on-avon_2023',
  //   phone: '021 0906 4735',
  //   website: 'https://www.wakaonavon.co.nz/',
  //   christchurch: 30,
  //   chchTransport: 30,
  // },
  {
    name: 'Jet Junkies',
    intro:
      'We are the ultimate Jetpack & Jet Ski, Kayak & Paddleboard experience!',
    imgfile: 'jet-junkies__2023',
    phone: '022 153 0780',
    address: 'Charlotte Jane Quay Lyttelton Christchurch',
    website: 'https://jetjunkies.co.nz',
    christchurch: 20,
    chchTransport: 20,
  },
  {
    name: 'Two Arrows Archery',
    intro:
      'Learn traditional Archery in a stunning peaceful location by the Maitai River. Equipment and instruction provided.',
    imgfile: 'two-arrows-archery__2023',
    phone: '022 610 2955',
    email: 'null',
    address: '532 Maitai Valley Road, Nelson',
    website: 'https://2arrowsarchery.com',
    // nelsonAirport: null,
    nelson: 71,
    // marlborough: null,
  },
  {
    name: 'Franz Josef Glacier Guides',
    intro:
      "We are proud to share with you the very best of New Zealand's glaciers. Immerse yourself on a journey of discovery, in one of the most pristine places on Earth. Our friendly and experienced guides will share their knowledge of the glacier region, leaving you with a whole new appreciation for Kā Roimata o Hine Hukatere (Franz Josef Glacier). Our tours are limited so be sure to book early!",
    imgfile: 'franz_josef_glacier_guides--2023',
    phone: '0800 484 337',
    email: 'walks@franzjosefglacier.com',
    address: '63 Cron Street, Franz Josef Glacier 7856, New Zealand',
    website: 'https://www.franzjosefglacier.com/',
    christchurch: 114,
    chchTransport: 114,
    westcoast: 607,
  },
  // {
  //   name: 'Kate Shepard House',
  //   intro:
  //     "Discover the story behind the celebrated leader of the New Zealand women's suffrage movement, Kate Sheppard.",
  //   imgfile: 'kate-sheppard-house_2023',
  //   phone: '03 341 1360',
  //   address: '83 Ctyde Road, Ilam, Christchurch',
  //   website:
  //     'https://visitheritage.co.nz/visit/upper-south-island/kate-sheppard-house/stories/an-introduction-to-te-whare-waiutuutu-kate-sheppard-house/',
  //   nelsonAirport: 95,
  // },
  {
    name: 'LUXURY DAY SPA',
    intro:
      'The beautiful Swan Lake Gardens and Lotus Day Spa are owned and operated by Duncan and Jeerana Laing. Their high standards and extensive business and spa therapy experience, make Swan Lake Gardens and Lotus Day Spa a world-class destination.',
    imgfile: 'lotus-day-spa-2023',
    phone: '+6439805400',
    email: 'info@lotusspa.co.nz',
    address: '9 Ernlea Terrace, Cashmere, Christchurch',
    website: 'https://www.lotusspa.co.nz/',
    christchurch: 115,
    chchTransport: 115,
  },
  {
    name: 'Jurassic Adventure',
    intro:
      "New Zealand's Number One Jurassic attraction! Jurassic Adventure is Christchurch's latest Dinosaur attraction. Get sent back in time and experience our life sized dinosaurs while playing Christchurch's best mini golf course! Mini golf not your fancy? Then go on the hunt for Dinosaur bones in our Dino dig. Or coming soon. The Raptor Run. The worlds first dinosaur themed maze & hunt!",
    imgfile: 'jurassic-adventures_2023',
    phone: null,
    email: 'null',
    address: '239A Opawa Road, Hillsborough, Christchurch',
    website: 'https://www.jurassicadventure.co.nz/',
    christchurch: 116,
    chchTransport: 116,
  },
];

export default BROCHURES;
